import React from "react";
import ferrumFooterLogo from '../assets/img/ferrum-footer-logo.svg';
import socialIcon from '../assets/img/social-icon-1.svg';
import twitterIcon from '../assets/img/footer-twitter.svg';
import instaIcon from '../assets/img/footer-insta.svg';

export const Footer = () => {
    return (
        <>
            {/* <div className="d_flex justify_start align_center footer">
                <div className="d_flex justify_start align_center w-100">
                    <a href={'https://discord.com/invite/dinos'} target={'_blank'}><img src={socialIcon} alt={socialIcon} /></a>
                    <a href={'https://twitter.com/DinosChibi'} target={'_blank'}><img src={twitterIcon} alt={twitterIcon} /></a>
                    <a href={'https://www.instagram.com/chibidinosnft/'} target={'_blank'}><img src={instaIcon} alt={instaIcon} /></a>
                </div>
                <div className="d_flex justify_end align_center">
                    <a href={'http://ferrum.network/'} target={'_blank'}><img src={ferrumFooterLogo} alt={ferrumFooterLogo} /></a>
                </div>
            </div> */}
        </>

    );
};
